var $ = jQuery.noConflict();

var siteUrl = window.location.origin === 'http://192.168.1.20' ? 'http://192.168.1.20/crownlaw' : window.location.origin;

 /*** Header fixed ***/
 $(window).on('scroll', function() {
  var scrollPixel = $(window).scrollTop();
  if (scrollPixel < 30) {
    $("header").removeClass("header-fix");
  } else {
    $("header").addClass("header-fix");
  }
});

document.addEventListener("DOMContentLoaded", function(){
  /*** Script For SVG CODE  ***/
  jQuery("img.svg").each(function () {
    var $img = jQuery(this);
    var imgID = $img.attr("id");
    var imgClass = $img.attr("class");
    var imgURL = $img.attr("src");
    jQuery.get(
      imgURL,
      function (data) {
        /*** Get the SVG tag, ignore the rest ***/
        var $svg = jQuery(data).find("svg");
        /*** Add replaced image's ID to the new SVG ***/
        if (typeof imgID !== "undefined") {
          $svg = $svg.attr("id", imgID);
        }
        /*** Add replaced image's classes to the new SVG ***/
        if (typeof imgClass !== "undefined") {
          $svg = $svg.attr("class", imgClass + " replaced-svg");
        }
        /*** Remove any invalid XML tags as per http://validator.w3.org ***/
        $svg = $svg.removeAttr("xmlns:a");
        /*** Check if the viewport is set, else we gonna set it if we can. ***/
        if (
          !$svg.attr("viewBox") &&
          $svg.attr("height") &&
          $svg.attr("width")
        ) {
          $svg.attr(
            "viewBox",
            "0 0 " + $svg.attr("height") + " " + $svg.attr("width")
          );
        }
        /*** Replace image with new SVG ***/
        $img.replaceWith($svg);
      },
      "xml"
    );
  });

  /*** Menu ****/
  $(".menu-item-has-children").prepend('<i class="caret"></i>');
  $("#mobile-menu .menu-item-has-children .sub-menu").prepend(`<li class="menu-item menu-back"><a><img src="${siteUrl}/wp-content/themes/beardog/assets/images/menu-back-arrow.svg" width="24" height="24" alt="arrow"> Back</a></li>`);

  $(".menu-item-has-children .caret").on('click', function(){
    $(this).parent().toggleClass("menu-show");
  });
  $(".menu-back").on('click', function(){
    $(this).closest('li.menu-show').removeClass("menu-show");
  });

  /*** Accordion ****/
  $('.accordion-collapse').on('shown.bs.collapse', function (e) {
    var $panel = $(this).closest('.accordion-item');
    $('html,body').animate({scrollTop:$panel.offset().top - 100}, 500); 
  });

  /***** Back to top *****/
  var progressPath = document.querySelector('.to-top path');
  var pathLength = progressPath.getTotalLength();
  progressPath.style.transition = progressPath.style.WebkitTransition = 'none';
  progressPath.style.strokeDasharray = pathLength + ' ' + pathLength;
  progressPath.style.strokeDashoffset = pathLength;
  progressPath.getBoundingClientRect();
  progressPath.style.transition = progressPath.style.WebkitTransition = 'stroke-dashoffset 10ms linear';		
  var updateProgress = function () {
    var scroll = $(window).scrollTop();
    var height = $(document).height() - $(window).height();
    var progress = pathLength - (scroll * pathLength / height);
    progressPath.style.strokeDashoffset = progress;
  }
  updateProgress();
  $(window).scroll(updateProgress);	
  var offset = 50;
  var duration = 550;
  jQuery(window).on('scroll', function() {
    if (jQuery(this).scrollTop() > offset) {
      jQuery('.to-top').addClass('active-progress');
    } else {
      jQuery('.to-top').removeClass('active-progress');
    }
  });				
  jQuery('.to-top').on('click', function(event) {
    event.preventDefault();
    jQuery('html, body').animate({scrollTop: 0}, duration);
    return false;
  })
	/***** Back to top End *****/

  /**** Form Js ****/
  //$('.wpcf7-form-control').on("cut copy paste",function(e) {e.preventDefault();});
  $('.wpcf7-tel').on('input', function(){
    $(this).val($(this).val().replace(/\D/g,'').slice(0, 10));
  });

  jQuery('.wpcf7-textarea').on('input', function () {
    var inputValue = jQuery(this).val();
    // Replace consecutive whitespaces with a single whitespace
    inputValue = inputValue.replace(/\s+/g, ' ');
    // Check if the input contains only whitespace characters
    if (/^\s+$/.test(inputValue)) {
      inputValue = ''; // If yes, replace with an empty string
    }
    jQuery(this).val(inputValue);
  });

  document.addEventListener( 'wpcf7mailsent', function(event) {
    location = `${siteUrl}/thank-you/`;
  }, false);
  
  $(".down-arrow").on('click', function (e) {
    e.preventDefault();
    var targetId = $(this).attr('href');
    $('html, body').animate({scrollTop: $(targetId).offset().top -90 }, 'slow');
  });

  $(document).on('click',  'a[href="#contact-form"]', function(s){
      s.preventDefault(), $("html, body").animate({
        scrollTop: $('#contact-form').offset().top - 100},
      'slow');
  });

  $(document).on('click', `a.btn-primary[href="${siteUrl}/contact/"]`, function(p){
    p.preventDefault(), $("html, body").animate({
      scrollTop: $('#contact-form').offset().top - 100},
    'slow');
  });

 /* $(document).on('click', `.single-content a[href="${siteUrl}/contact/"]`, function(d){
    d.preventDefault(), $("html, body").animate({
      scrollTop: $('#contact-form').offset().top - 100},
    'slow');
  });*/

  $(".header-search-icon-img").on('click', function(){
    $(".search-form").toggleClass("form-open");
  });

  $('.side-mail-icon').on('click', function() {
    $(this).parent('.siteform').toggleClass('active');
  });
  $('.closebtn').on('click', function() {
    $(this).closest('.siteform').removeClass('active');
  }); 

  $(document).on("click", ".menu-item a[title='Contact']", function () { 
    localStorage.setItem('attrBtn', 'Clicked');
  });

  if (!localStorage.getItem('attrBtn') && location.pathname === "/contact/") {
    setTimeout(function() {
      $('html,body').animate({scrollTop: $("#wpcf7-f7-o1").offset().top - 120}, 500);
    }, 100);
  }

  setTimeout(() => localStorage.removeItem("attrBtn"), 3000);
  
});